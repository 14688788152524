
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function OrdersTable(props) {

    return (
        <>
        {console.log(props.rows)}
            <TableContainer component={Paper} style={{ position: 'relative', zIndex: '9', width: '90vw', margin: 'auto', marginTop: '2rem' }}>
                <Table sx={{ minWidth: '100%' }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Order</StyledTableCell>
                            <StyledTableCell align="right">Item</StyledTableCell>
                            <StyledTableCell align="right">Amount</StyledTableCell>
                            <StyledTableCell align="right">Status</StyledTableCell>
                            <StyledTableCell align="right">Date</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(props.rows).map((key,i) => (
                            <StyledTableRow key={key}>
                                <StyledTableCell component="th" scope="row">
                                    {props.rows[key].section.split("-")[1]}
                                </StyledTableCell>
                                <StyledTableCell align="right">{props.rows[key].item}</StyledTableCell>
                                <StyledTableCell align="right">{props.rows[key].cost}</StyledTableCell>
                                <StyledTableCell align="right">{props.rows[key].status}</StyledTableCell>
                                <StyledTableCell align="right">{props.rows[key].timedate}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}