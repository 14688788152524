
import { generateClient } from 'aws-amplify/api';
import { createAuraStoreTransactions } from '../graphql/mutations';


const client = generateClient();

export async function PaymentHnadler(props) {
    const d = new Date();

    let dt = `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    let trxid = `${dt.replaceAll("/","").replaceAll(":","").replaceAll(" ","")}-${props.userID}-${props.serverID}`;

    let orderJson = {
        trxid: trxid,
        amount: props.amount,
        // amount: "1",
        purchaseitems: props.selectedPack,
        timedate: dt,
        trxstatus: "Order Created",
        user: `${props.userID}-${props.serverID}`,
        ef1: JSON.stringify({
            "email" : props.emailID,
            "mob" : props.phoneNumber,
            "orderItem" : props.ordertype
        })
    }
    const apiData = await client.graphql({
        query: createAuraStoreTransactions,
        variables: {
            input: orderJson
        },
    });
    return orderJson;
}

export async function createPaymentLink(props,orderJson) {
    let ud = JSON.parse(orderJson['ef1'])
    var data = JSON.stringify({
        client_txn_id: orderJson.trxid,
        amount: orderJson.amount,
        p_info: orderJson.purchaseitems,
        customer_name: orderJson.user,
        customer_email: ud.email,
        customer_mobile: ud.mob,
        redirect_url:
            "https://7fvsvee76rrjzknxcpy5lgkgyq0ratoq.lambda-url.ap-south-1.on.aws/",
        udf1: props.userID,
        udf2: props.serverID,
        udf3: props.ordertype,
    });

    console.log("data ", data)
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            // console.log(this.responseText['link'])
            let tempresponse = JSON.parse(this.responseText);
            // let bodyjson = JSON.parse(tempresponse["body"]);
            if (tempresponse["status"] === "true") {
              window.location.href = tempresponse["link"];
            }
        }
    });

    xhr.open(
        "POST",
        "https://tlbjrfychi7azmbb3w6vdkdwkm0xzqsg.lambda-url.ap-south-1.on.aws/"
    );
    xhr.setRequestHeader("udfxr", btoa(`${orderJson.amount}-${orderJson.purchaseitems}`));
    xhr.send(data);
}