import React from 'react';
import { motion } from 'framer-motion';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Paper 
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Footer from './Footer';
import ProfessionalNavbar from './ProfessionalNavbar';

const GameImage = styled('img')({
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    }
});

const SuccessPaper = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(145deg, #1e1e2f, #16162a)',
    borderRadius: '15px',
    padding: theme.spacing(4),
    color: '#ffffff',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
    border: '1px solid rgba(255,255,255,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
}));

export default function Success() {
    return (
        <>
            <Footer />
            <Container maxWidth="lg">
                {/* <GameImage src='/mlbb.jpg' alt="mlbb" /> */}
                <Box sx={{
                    background: 'linear-gradient(to right, #1a1a2e, #16213e)',
                    // minHeight: '100vh',
                    pt: 4,
                    pb: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <SuccessPaper>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                    sx={{
                                        color: '#ffffff',
                                        fontWeight: 'bold',
                                        textShadow: '0 0 10px rgba(37, 117, 252, 0.5)',
                                        mb: 3
                                    }}
                                >
                                    Aura Official Store
                                </Typography>

                                <motion.div
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6 }}
                                >
                                    <Typography 
                                        variant="h5" 
                                        color="primary" 
                                        sx={{ 
                                            // color: '#00f0ff', 
                                            mb: 2 
                                        }}
                                    >
                                        Payment Failed!
                                    </Typography>

                                    <Typography 
                                        variant="body1" 
                                        sx={{ 
                                            color: 'white', 
                                            mb: 3,
                                            opacity: 0.8
                                        }}
                                    >
                                        Please try to recharge again
                                    </Typography>

                                    <motion.img 
                                        src="/miya_cry.gif" 
                                        alt="Thank you" 
                                        style={{ 
                                            width: '250px', 
                                            height: '250px', 
                                            borderRadius: '15px',
                                            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)'
                                        }}
                                        initial={{ scale: 0.8, opacity: 0 }}
                                        animate={{ scale: 1, opacity: 1 }}
                                        transition={{ duration: 0.6 }}
                                    />
                                </motion.div>
                            </SuccessPaper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <ProfessionalNavbar />
        </>
    );
}