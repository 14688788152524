import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Home as HomeIcon,
    ListAlt as OrderIcon,
    WhatsApp as WhatsAppIcon,
    Instagram as InstagramIcon,
    HeadsetMic
} from '@mui/icons-material';

import "../css/ProfessionalNavbar.css";

const ProfessionalNavbar = ({ view }) => {
    const navigate = useNavigate();

    const socialLinks = [
        {
            icon: <InstagramIcon className="social-icon instagram" />,
            href: "https://www.instagram.com/_aura_official_store_"
        },
        {
            icon: <WhatsAppIcon className="social-icon whatsapp" />,
            href: "https://whatsapp.com/channel/0029VatRWNwEwEjyckkqzQ3M"
        },
        {
            icon: <HeadsetMic className="social-icon support" />,
            href: 'https://wa.me/916002245165'
        }
    ];

    return (
        <nav className="professional-navbar-bottom">
            <div className="navbar-container">


                <div className="contact-section">
                    <div className="navigation-section">
                        {view === "history" ? (
                            <button
                                onClick={() => navigate('/')}
                                className="nav-button home-button"
                            >
                                <HomeIcon />
                                <span>Home</span>
                            </button>
                        ) : (
                            <button
                                onClick={() => navigate('/history')}
                                className="nav-button orders-button"
                            >
                                <OrderIcon />
                                <span style={{fontSize:'10px'}}>My Orders</span>
                            </button>
                        )}
                    </div>
                    <div className="contact-label">Contact:</div>
                    <div className="social-links">
                        {socialLinks.map((link, index) => (
                            <a
                                key={index}
                                href={link.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                {link.icon}
                            </a>
                        ))}
                    </div>
                </div>

                <span style={{fontSize:'10px'}}>All Rights Reserved @TheCodeFather 2024-25</span>
            </div>
        </nav>
    );
};

export default ProfessionalNavbar;