const config_json = {
  API: {
    GraphQL: {
      endpoint: 'https://pfqick2ldref7jzctlhajz7dbm.appsync-api.ap-south-1.amazonaws.com/graphql',
      region: "ap-south-1",
      // Set the default auth mode to "apiKey" and provide the API key value
      defaultAuthMode: 'apiKey',
      apiKey: "da2-xgwatsnppfgd3oma6s5ramfhb4"
    }
  }
  };
  
  export default config_json;
  