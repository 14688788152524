
import PwaWithInstallButton from './PwaWithInstallButton';
import BackButton from './BackButton';

export default function Footer(params) {

    return (
        <>
            <div className="footer-div">
                <span style={{ fontSize: '15px' }}>Install the App for quick access</span>
                <PwaWithInstallButton />
            </div>
            <BackButton />

        </>
    )
}