/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAuraStoreItems = /* GraphQL */ `
  query GetAuraStoreItems($itemname: String!) {
    getAuraStoreItems(itemname: $itemname) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const listAuraStoreItems = /* GraphQL */ `
  query ListAuraStoreItems(
    $filter: TableAuraStoreItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuraStoreItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        itemname
        isactive
        storeitems
        ef1
        ef2
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuraStoreTransactions = /* GraphQL */ `
  query GetAuraStoreTransactions($trxid: String!) {
    getAuraStoreTransactions(trxid: $trxid) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const listAuraStoreTransactions = /* GraphQL */ `
  query ListAuraStoreTransactions(
    $filter: TableAuraStoreTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuraStoreTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        trxid
        timedate
        user
        trxstatus
        purchaseitems
        amount
        ef1
        ef2
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuraStoreUserHistory = /* GraphQL */ `
  query GetAuraStoreUserHistory($userid: String!) {
    getAuraStoreUserHistory(userid: $userid) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
export const listAuraStoreUserHistories = /* GraphQL */ `
  query ListAuraStoreUserHistories(
    $filter: TableAuraStoreUserHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuraStoreUserHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userid
        orders
        subscription
        isblocked
        __typename
      }
      nextToken
      __typename
    }
  }
`;
