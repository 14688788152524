
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from "./Footer";
import ProfessionalNavbar from './ProfessionalNavbar';

import React from 'react';
import { motion } from 'framer-motion';
import { Button, Typography, Container, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GamepadIcon } from 'lucide-react';

const GameCard = styled(motion.div)(({ theme }) => ({
    background: 'linear-gradient(145deg, #2c2c3e, #1f1f2e)',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
    transition: 'all 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 15px 45px rgba(0, 0, 0, 0.7)',
    }
}));

const GameImage = styled('img')({
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    }
});

const RechargeButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)',
    color: '#ffffff',
    padding: theme.spacing(1.5, 3),
    borderRadius: '25px',
    fontWeight: 'bold',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
        background: 'linear-gradient(45deg, #2575fc 0%, #6a11cb 100%)',
        transform: 'translateY(-3px)',
        boxShadow: '0 4px 17px rgba(0, 0, 0, 0.35)',
    }
}));


export default function MainPage(params) {

    const navigate = useNavigate();
    return (
        <>
            <Footer />
            <Container maxWidth="lg" sx={{
                background: 'linear-gradient(to right, #1a1a2e, #16213e)',
                minHeight: '100vh',
                pt: 4,
                pb: 4
            }}>
                <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    sx={{
                        color: '#ffffff',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textShadow: '0 0 10px rgba(37, 117, 252, 0.5)'
                    }}
                >
                    {/* <GamepadIcon style={{ marginRight: 10 }} />  */}
                    Aura Official Store
                </Typography>

                <Grid container spacing={3} justifyContent="center">
                    {[
                        { img: "/mlbb.jpg", name: "Mobile Legends", route: "/mlbb" },
                        { img: "/pubg.jpg", name: "PUBG Mobile", route: "/pubg" },
                        { img: "/genshin.jpg", name: "Genshin Impact", route: "/genshin" }
                    ].map((game, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <GameCard
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                            >
                                <GameImage src={game.img} alt={game.name} />
                                <Box sx={{ p: 2, textAlign: 'center' }}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: '#ffffff',
                                            mb: 2,
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {game.name}
                                    </Typography>
                                    <RechargeButton
                                        variant="contained"
                                        onClick={() => navigate(game.route)}
                                        disabled={game.name !== "Mobile Legends"? true:false }
                                    >
                                        {game.name !== "Mobile Legends"? "Coming Soon":"Recharge Now" }
                                    </RechargeButton>
                                </Box>
                            </GameCard>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <ProfessionalNavbar />
        </>
    );
}