import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';

const StyledBackButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  top: '2rem',
  left: '1rem',
  zIndex: 1000,
  background: 'linear-gradient(145deg, #1a1a2e, #16213e)',
  color: '#e94560',
  border: '2px solid #e94560',
  padding: '8px 20px',
  borderRadius: '8px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  transition: 'all 0.3s ease',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    background: 'linear-gradient(145deg, #16213e, #1a1a2e)',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(233, 69, 96, 0.2)',
  }
}));

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Don't render on main page, success, or fail pages
  if (location.pathname === '/'){
    return null;
  }

  if (
    location.pathname === '/success' || 
    location.pathname === '/fail')  {
    return(
        <StyledBackButton
        onClick={() => navigate('/')}
        startIcon={<ArrowBack />}
        >
        Home
        </StyledBackButton>
    )
  }

  return (
    <StyledBackButton
      onClick={() => navigate(-1)}
      startIcon={<ArrowBack />}
    >
      Back
    </StyledBackButton>
  );
};

export default BackButton;