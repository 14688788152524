/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAuraStoreItems = /* GraphQL */ `
  mutation CreateAuraStoreItems($input: CreateAuraStoreItemsInput!) {
    createAuraStoreItems(input: $input) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const updateAuraStoreItems = /* GraphQL */ `
  mutation UpdateAuraStoreItems($input: UpdateAuraStoreItemsInput!) {
    updateAuraStoreItems(input: $input) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const deleteAuraStoreItems = /* GraphQL */ `
  mutation DeleteAuraStoreItems($input: DeleteAuraStoreItemsInput!) {
    deleteAuraStoreItems(input: $input) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const createAuraStoreTransactions = /* GraphQL */ `
  mutation CreateAuraStoreTransactions(
    $input: CreateAuraStoreTransactionsInput!
  ) {
    createAuraStoreTransactions(input: $input) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const updateAuraStoreTransactions = /* GraphQL */ `
  mutation UpdateAuraStoreTransactions(
    $input: UpdateAuraStoreTransactionsInput!
  ) {
    updateAuraStoreTransactions(input: $input) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const deleteAuraStoreTransactions = /* GraphQL */ `
  mutation DeleteAuraStoreTransactions(
    $input: DeleteAuraStoreTransactionsInput!
  ) {
    deleteAuraStoreTransactions(input: $input) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const createAuraStoreUserHistory = /* GraphQL */ `
  mutation CreateAuraStoreUserHistory(
    $input: CreateAuraStoreUserHistoryInput!
  ) {
    createAuraStoreUserHistory(input: $input) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
export const updateAuraStoreUserHistory = /* GraphQL */ `
  mutation UpdateAuraStoreUserHistory(
    $input: UpdateAuraStoreUserHistoryInput!
  ) {
    updateAuraStoreUserHistory(input: $input) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
export const deleteAuraStoreUserHistory = /* GraphQL */ `
  mutation DeleteAuraStoreUserHistory(
    $input: DeleteAuraStoreUserHistoryInput!
  ) {
    deleteAuraStoreUserHistory(input: $input) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
